<template>
    <div class="forgot">
        <div class="left" :style="{'background-image': `url(${require(`@/assets/img/${language}.png`)})`}"></div>
        <div class="right">
            <img class="close" :src="require('@/assets/img/close.svg')" alt="close" @click="$router.go(-1)">

            <div class="forgot-container">
                <div class="upper">
                    <img :src="require('@/assets/img/logo.png')" alt="logo" class="logo">
                    <p class="title">Forgot Password</p>
                    <p class="desc">Please enter your email</p>
                </div>

                <div class="lower">
                    <form @submit.prevent="forgot()" class="forgot-form">
                        <input
                            type="email"
                            placeholder="Email Address"
                            v-model="email"
                            required
                        />

                        <p class="_error" v-if="error.email">{{ error.email[0] }}</p>

                        <button type="submit" class="submit" :disabled="loading">Submit</button>
                    </form>
                </div>
            </div>

            <div class="footer" v-if="!user">
                <p>Already have an account?</p>
                <router-link to="/sign-in" class="signin">Sign In</router-link>
            </div>
        </div>

        
        <Banner :visible="banner.status" class="banner"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            isShow: false,
            confirmIsShow: false,
            email: ''
        }
    },

    components: { 
        Banner: () => import('@/components/utils/Banner')
    },

    computed: {
        ...mapState({
            user: state => state.user,
            error: state => state.forgotError,
            loading: state => state.loading,
            language: state => state.language,
            banner: state => state.banner
        })
    },

    methods: {
        async forgot () {
            let form = new FormData
            
            form.append('email', this.email)
            await this.$store.dispatch('forgotPassword', form)
        }
    }
}
</script>

<style lang="scss" scoped>
.forgot {
    display: flex;

    .left {
        @include desktop {
            flex: 1;
            height: 100vh;
            // background: #47435c url('~@/assets/img/sign_placeholder.jpg') no-repeat center top;
            background-repeat: no-repeat;
            background-size: cover;
            // opacity: 0.8;
            border-radius: 0 4px 4px 0;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        .close {
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 30px;

            width: 15px;
            height: 15px;

            @include desktop {
                width: unset;
                height: unset;
            }
        }

        .forgot-container {
            padding: 0 20px;
            // margin: auto;
            margin: 70px auto 20px;

            @include desktop {
                margin: auto;
                width: 425px;
            }

            .upper {
                margin-bottom: 20px;

                .logo {
                    display: block;
                    width: 150px;
                    height: 100px;
                    margin: auto;

                    @include desktop {
                        display: none;
                    }
                }

                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 12px;
                    // color: #00AEEF;
                }

                .desc {
                    color: #B8B8B8;
                    font-size: 14px;
                    margin-bottom: 40px;
                }
            }

            .lower {
                display: flex;
                flex-direction: column;

                input {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #000000;
                    padding: 20px 15px;
                    font-size: 14px;
                    color: #00000099;
                    margin-bottom: 16px;
                }

                ._error {
                    margin-bottom: 15px;
                }

                .submit {
                    width: 100%;
                    background-color: #00AEEF;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 16px;
                    padding: 18px;

                    &:disabled {
                        opacity: 0.7;
                        pointer-events: none;
                    }
                }
            }
        }

        .footer {
            margin: 0 auto;
            justify-content: center;
            display: flex;
            padding-bottom: 30px;

            @include desktop {
                width: 425px;
            }

            p {
                font-size: 16px;
                margin-right: 3px;
            }

            .signin {
                color: #00AEEF;
                font-size: 16px;
            }
        }
    }

    /deep/ .notification-banner {
        bottom: 2em;
        right: 2em;
        left: unset;
    }
}
</style>